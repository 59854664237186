var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-4" },
          [
            _c(
              "CButton",
              {
                staticClass: "px-0",
                attrs: { color: "link" },
                on: { click: _vm.openingTimesCopyTo },
              },
              [
                _vm.copySucceeded.openingTimesCopyTo
                  ? _c(
                      "span",
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-check-alt" },
                        }),
                        _vm._v("Applied!"),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-copy" },
                        }),
                        _vm._v("Apply Monday to all days"),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        ),
        _vm._m(0),
        _vm._m(1),
      ]),
      _c("restaurant-times", {
        attrs: { day: "Monday", time: _vm.$v.form.open_monday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.mo = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_monday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Tuesday", time: _vm.$v.form.open_tuesday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.tu = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_tuesday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Wednesday", time: _vm.$v.form.open_wednesday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.we = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_wednesday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Thursday", time: _vm.$v.form.open_thursday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.th = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_thursday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Friday", time: _vm.$v.form.open_friday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.fr = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_friday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Saturday", time: _vm.$v.form.open_saturday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.sa = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_saturday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Sunday", time: _vm.$v.form.open_sunday.$model },
        on: {
          isValid: (value) => (_vm.isValidOpeningTime.su = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.open_sunday, "$model", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-3 d-none d-sm-block px-sm-0" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Open from")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-3 d-none d-sm-block" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Open till")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }